interface PvArrayProps {
  tables: number;
  tiers: number;
  rows: number;
  columns: number;
  panel: Parameters<typeof calculatePvPanelDimensions>[0];
  rowSpacing?: number;
  tableGap?: number;
  tierGap?: number;
  columnGap?: number;
  azimuth?: number;
  groundClearance?: number;
  orientation?: "portrait" | "landscape";
}

interface PvPanelProps {
  rows: number;
  columns: number;
  variant: "physical" | "digital" | string;
  frontIrradiance?: Array<number[]>;
  rearIrradiance?: Array<number[]>;
  drawIrradiance?: boolean;
  cellWidth: number;
  cellHeight: number;
  cellShape?: "rect" | "oct";
  cellSpacingX: number;
  cellSpacingY: number;
  panelBoxWidth: number;
  panelBoxHeight: number;
  panelPaddingX: number;
  panelPaddingTop: number;
  panelPaddingBottom: number;
  tilt?: number;
  frame?: boolean;
  frameShortSideWidth: number;
  frameLongSideWidth: number;
  frameDepth: number;
  glass?: boolean;
  panel?: boolean;
  bifacial?: boolean;
  halfCutCenterSpace?: number;
  type: "full" | "half";
}

type PvPanelDimensionProps = Pick<
  PvPanelProps,
  | "cellHeight"
  | "cellWidth"
  | "cellSpacingX"
  | "cellSpacingY"
  | "panelPaddingX"
  | "panelPaddingTop"
  | "panelPaddingBottom"
  | "columns"
  | "rows"
  | "halfCutCenterSpace"
>;

interface PanelDimensions {
  cellBoxHeight: number;
  cellBoxWidth: number;
  panelBoxHeight: number;
  panelBoxWidth: number;
}

export function calculatePvPanelDimensions({
  cellHeight,
  cellWidth,
  cellSpacingX,
  cellSpacingY,
  panelPaddingX,
  panelPaddingTop,
  panelPaddingBottom,
  columns,
  rows,
  halfCutCenterSpace = 0,
}: PvPanelDimensionProps): PanelDimensions {
  const cellBoxWidth = cellWidth + cellSpacingX;
  const cellBoxHeight = cellHeight + cellSpacingY;

  const panelBoxWidth = cellBoxWidth * columns + panelPaddingX * 2;
  const panelBoxHeight =
    cellBoxHeight * rows +
    (halfCutCenterSpace + panelPaddingTop + panelPaddingBottom) * 2;

  return {
    cellBoxHeight,
    cellBoxWidth,
    panelBoxWidth,
    panelBoxHeight,
  };
}

type PvTableDimensionProps = Pick<
  PvArrayProps,
  | "columns"
  | "tiers"
  | "rowSpacing"
  | "tableGap"
  | "tierGap"
  | "columnGap"
  | "orientation"
  | "azimuth"
> &
  Pick<PanelDimensions, "panelBoxHeight" | "panelBoxWidth">;
export function calculatePvTableDimensions({
  orientation,
  panelBoxHeight,
  panelBoxWidth,
  //
  columns,
  tiers,
  rowSpacing = 0,
  tableGap = 0,
  tierGap = 0,
  columnGap = 0,
  azimuth = 0,
}: PvTableDimensionProps) {
  const isLandscape = orientation === "landscape";
  const panelDimX = isLandscape ? panelBoxHeight : panelBoxWidth;
  const panelDimY = isLandscape ? panelBoxWidth : panelBoxHeight;

  const totalTierGap = tierGap * tiers;
  const tableHeight = panelDimY * tiers + totalTierGap;

  const totalColumnGap = columnGap * columns;
  const tableWidth = panelDimX * columns + totalColumnGap;

  const bboxDimensions = {
    width: tableWidth + tableGap,
    height: rowSpacing,
  };

  return {
    tableHeight,
    tableWidth,
    bboxDimensions,
    tableGap,
    rowSpacing,
    azimuth,
  };
}

export function pvTableGridSize(
  dimensions: ReturnType<typeof calculatePvTableDimensions>
) {
  if (Math.abs(dimensions.azimuth) === 90) {
    return {
      gridCellWidth: dimensions.bboxDimensions.height,
      gridCellHeight: dimensions.bboxDimensions.width,
    };
  }

  return {
    gridCellWidth: dimensions.bboxDimensions.width,
    gridCellHeight: dimensions.bboxDimensions.height,
  };
}
