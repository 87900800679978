import {
  Avatar,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { TbChevronDown } from "react-icons/tb";
import { useAppUrls } from "~/appUrls";
import { useAuth } from "~/features/auth/AuthContext";
import { useFeatureFlags } from "~/features/featureFlag/hooks";
import { useOrganization } from "~/features/organization/useOrganization";
import { useActiveUser } from "~/features/user/hooks";
import { RouterLink } from "../Link";

export function AppBarUserAvatar() {
  const auth = useAuth();
  const { user } = useActiveUser();
  const { organization } = useOrganization();
  const { isOrganizationsEnabled } = useFeatureFlags(["organizations"]);
  const fullName =
    user?.first_name ?? user?.last_name
      ? [user?.first_name, user?.last_name].join(" ")
      : user?.email ?? "";
  const url = useAppUrls();

  if (!auth.isLoggedIn) return null;

  let menuButtonContent = <Avatar size="sm" name={fullName} />;

  if (isOrganizationsEnabled && user) {
    menuButtonContent = (
      <HStack>
        <Stack spacing={0} textAlign={"right"} fontSize={"xs"} lineHeight={1}>
          <Text fontWeight={"semibold"}>{organization?.name}</Text>
          <Text>{user.email}</Text>
        </Stack>
        {/* add org image once we support it */}
        {/* <Avatar
          size="sm"
          name={organization ?? ""}
          borderColor={"whiteAlpha.800"}
          borderWidth={"1px"}
        >
          <Image src="/e-white.png" w="100%" p={1.5} />
        </Avatar> */}
        <Icon as={TbChevronDown} color="white" />
      </HStack>
    );
  }

  // TODO: implement org switcher
  return (
    <Menu>
      <MenuButton>{menuButtonContent}</MenuButton>
      <MenuList
        boxShadow={"lg"}
        color="black"
        fontFamily={"body"}
        textTransform={"none"}
      >
        {isOrganizationsEnabled && (
          // TODO: allow switching from this menu
          <>
            <MenuGroup title={organization?.name ?? "No organization"}>
              <MenuItem as={RouterLink} to={url.organizationPicker()}>
                Switch Organization
              </MenuItem>
              {organization && (
                <MenuItem as={RouterLink} to={url.settingsOrganizationEdit()}>
                  Organization Settings
                </MenuItem>
              )}
            </MenuGroup>
            <MenuDivider />
          </>
        )}
        <MenuGroup title={fullName}>
          <MenuItem as={RouterLink} to={url.settingsUserAccountEdit()}>
            User Settings
          </MenuItem>

          <MenuDivider />
          <MenuItem
            as={Link}
            href="https://docs.google.com/forms/d/e/1FAIpQLScB44YHfOKVT5XXoVtkS8tlejaUTylI4Ct1iHPHS6rIIjhpRw/viewform?pli=1"
          >
            Contact
          </MenuItem>
          <MenuDivider />

          <MenuItem onClick={() => auth.logout()}>Logout</MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
