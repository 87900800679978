import { Box, Center, Divider, Heading, Image, Stack } from "@chakra-ui/react";
import { ReactNode } from "react";

interface AuthFormContainerProps {
  title: string;
  children: ReactNode;
}

export function AuthFormContainer({ title, children }: AuthFormContainerProps) {
  return (
    <Stack
      w="100%"
      maxW={"500px"}
      align="center"
      p={8}
      pb={12}
      // py={20}
      spacing={4}
      maxH="100%"
      overflowY={"auto"}
      position={"relative"}
      bg="white"
      borderRadius={"2xl"}
    >
      <Center w="100px" py={2} borderRadius={"2xl"} justifySelf={"start"}>
        <Image src="/enurgen-black.png" />
      </Center>

      <Box position="relative" px={10} py={0} w={"100%"}>
        <Divider />
      </Box>
      <Heading as="h2" size="md" mb={6}>
        {title}
      </Heading>
      {children}
    </Stack>
  );
}
