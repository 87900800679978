import {
  Button,
  Divider,
  HStack,
  Icon,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { MultiFactorInfo } from "firebase/auth";
import { TbMessageDots, TbPasswordMobilePhone } from "react-icons/tb";
import { isPhoneFactor, isTOTPFactor } from "../util";

interface MFAListProps {
  enrolledMFA: MultiFactorInfo[];
  onSelect?: (mfaInfo: MultiFactorInfo) => void;
  onDelete?: (mfaInfo: MultiFactorInfo) => void;
}

export function MFAList({ enrolledMFA, onSelect, onDelete }: MFAListProps) {
  return (
    <Stack spacing={0} w="100%" divider={<Divider />}>
      {enrolledMFA.map((factor, idx) => (
        <HStack
          as={onSelect ? "button" : "div"}
          key={factor.uid}
          py={6}
          px={2}
          onClick={() => onSelect?.(factor)}
          cursor={onSelect ? "pointer" : undefined}
          _hover={onSelect ? { bg: "gray.50" } : undefined}
        >
          {isPhoneFactor(factor) && (
            <>
              <Icon as={TbMessageDots} fontSize="2xl" />
              <Text>Phone</Text>
              <Text>{factor.phoneNumber}</Text>
            </>
          )}
          {isTOTPFactor(factor) && (
            <>
              <Icon as={TbPasswordMobilePhone} fontSize="2xl" />
              <Text>Authenticator App</Text>
            </>
          )}
          <Spacer />
          {/* Todo */}
          {onDelete && (
            <Button size="xs" variant="ghost" onClick={() => onDelete(factor)}>
              Delete
            </Button>
          )}
        </HStack>
      ))}
    </Stack>
  );
}
