import {
  Box,
  Center,
  Code,
  Divider,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import { type FallbackRender } from "@sentry/react";
import { Fragment, useEffect } from "react";
import { TbCloudExclamation } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import usePrevious from "~/hooks/usePrevious";
import { AppBarBase } from "../AppBar/AppBar";
import { Well } from "../Well/Well";

export const FallbackFullPage: FallbackRender = (props) => {
  // eslint-disable-next-line react/prop-types
  const { error, componentStack, eventId } = props;

  return (
    <Fragment>
      <Flex direction="column" w="100vw" h="100vh" position={"relative"}>
        <AppBarBase />
        <Center h="100%" w="100%">
          <Stack>
            <Icon as={TbCloudExclamation} boxSize={10} />
            <Text
              size="h2"
              fontSize={"4xl"}
              fontFamily={"mono"}
              fontWeight={"black"}
            >
              Error
            </Text>
            <Box>
              <Text>
                Our team has been notified and will resolve the issue soon.
              </Text>
              <Text>
                If you require immediate assistance, please contact us and
                reference the following Event ID.
              </Text>
            </Box>

            <HStack>
              <Text>
                <b>Event ID:</b>
              </Text>
              <Code>{eventId}</Code>
            </HStack>
            <Divider my={1} />
            <Well>
              <Box maxW="container.lg" maxH="500px" overflow={"auto"}>
                <pre>
                  {error.toString()}
                  {componentStack}
                </pre>
              </Box>
            </Well>
          </Stack>
        </Center>
      </Flex>

      {/* eslint-disable-next-line react/prop-types */}
      <FallbackLocationReset error={error} resetError={props.resetError} />
    </Fragment>
  );
};

// this is purposefully a renderless component
// as the ErrorBoundary is a render function, not a component and complains about
// hooks being called within a Class Component...
function FallbackLocationReset({
  resetError,
}: {
  error: Error;
  resetError: () => void;
}) {
  const location = useLocation();
  const prevLocation = usePrevious(location.pathname);

  useEffect(() => {
    if (prevLocation !== undefined && prevLocation !== location.pathname) {
      resetError();
    }
  }, [location]);
  return null;
}

export const FallbackChart: FallbackRender = (props) => {
  return (
    <Center h="100%" flexDir={"column"} py={20} gap={5} textAlign={"center"}>
      <Text size="md" fontFamily={"mono"} fontWeight={"black"}>
        Something went wrong
      </Text>
      <Text>Our team has been notified and will resolve the issue soon.</Text>
    </Center>
  );
};
