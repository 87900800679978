import { addHours } from "date-fns";
import { FirebaseError } from "firebase/app";
import { CookieAttributes } from "js-cookie";
import { useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  api,
  useLocalLoginMutation,
  useRefreshTokenQuery,
  type AuthError,
  type FirebaseAppLoginMutation,
  type FirebaseAppRegisterMutation,
  type LocalLoginMutation,
  type RefreshTokenQuery,
  type TokenPayload,
} from "~/gql/generated";
import { useCookies } from "~/hooks/useCookies";
import { useSessionStorageState } from "~/hooks/useStorageState";
import { useAppDispatch, useAppSelector } from "~/redux/store";
import { setUser } from "../user/userSlice";
import { AuthContext } from "./AuthContext";
import { useFirebaseAuth, useFirebaseMFA } from "./useFirebaseAuth";
import { parseJwtPayload, type AuthTokenPayload } from "./util";

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [authError, setAuthError] = useState<AuthError | null>(null);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const {
    authToken,
    authTokenParsed,
    isImpersonating,
    setAuthToken,
    removeAuthToken,
    startImpersonate,
    endImpersonate,
  } = useAuthCookie();

  const firebaseAuthHookValue = useFirebaseAuth();
  const firebaseMFA = useFirebaseMFA(firebaseAuthHookValue);

  const {
    firebaseAuth,
    firebaseUser,
    firebaseAuthStateLoading,
    firebaseSignInWithEmailAndPasswordError,
    firebaseCreateUserWithEmailAndPasswordError,
    firebaseAppLoginMutationResponse,
    firebaseAppRegisterMutationResponse,
    firebaseSignInWithEmailAndPasswordLoading,
    firebasePasswordResetEmailIsLoading,
    firebaseSignInWithEmailAndPassword,
    firebaseSendPasswordResetEmail,
    firebaseCreateUserWithEmailAndPassword,
    clearFirebaseError,
  } = firebaseAuthHookValue;

  const [localLoginMutation, loginResult] = useLocalLoginMutation();

  const [tabSessionId] = useSessionStorageState("tabSessionId", uuid());
  const logoutBroadcastChannel = useMemo(
    () => new BroadcastChannel("logout"),
    []
  );

  useEffect(() => {
    if (user && !authToken) {
      dispatch(setUser(null));
    }
  }, [user, authToken]);

  const _logout = async () => {
    if (firebaseUser) {
      await firebaseAuth.signOut();
    }
    removeAuthToken();
    dispatch(api.util.resetApiState());
    clearErrors();
  };

  const logout = async () => {
    _logout();
    logoutBroadcastChannel.postMessage({
      tabSessionId,
    });
  };

  useEffect(() => {
    function listener(e: MessageEvent) {
      const msg = e.data;
      if (msg.tabSessionId === tabSessionId) return;
      _logout();
    }
    logoutBroadcastChannel.addEventListener("message", listener);

    return () => {
      logoutBroadcastChannel.removeEventListener("message", listener);
    };
  }, []);

  const refreshTokenResult = useRefreshAuthToken({
    authToken,
    authTokenParsed,
    setAuthToken,
    logout,
  });

  const localLogin = async (username: string, password: string) => {
    localLoginMutation({
      username,
      password,
    });
  };

  async function refreshToken() {
    await refreshTokenResult.refetch();
  }

  useEffect(() => {
    const tokenOrError = getTokenPayloadOrAuthError(
      loginResult.data,
      firebaseAppLoginMutationResponse.data ??
        firebaseAppRegisterMutationResponse.data
    );

    if (!tokenOrError) return;
    if (tokenOrError.__typename === "AuthError") {
      setAuthError(tokenOrError);
      return;
    }
    if (tokenOrError.__typename === "TokenPayload") {
      setAuthToken(tokenOrError.token);
    }
  }, [
    loginResult.data,
    firebaseAppLoginMutationResponse.data,
    firebaseAppRegisterMutationResponse.data,
  ]);

  const isError =
    Boolean(authError) ||
    loginResult.isError ||
    refreshTokenResult.isError ||
    firebaseSignInWithEmailAndPasswordError ||
    firebaseCreateUserWithEmailAndPasswordError;

  const err =
    authError ??
    loginResult.error ??
    refreshTokenResult.error ??
    firebaseSignInWithEmailAndPasswordError ??
    firebaseCreateUserWithEmailAndPasswordError;

  useEffect(() => {
    // the refresh token result handler will handle the error accordingly and logout if needed
    // this effect is a catch all for everything else
    if (!isError || refreshTokenResult.error) {
      return undefined;
    }

    if (
      err instanceof FirebaseError &&
      err.code === "auth/multi-factor-auth-required"
    ) {
      return;
    }

    logout();
  }, [isError]);

  function clearErrors() {
    setAuthError(null);
    clearFirebaseError();
  }

  const isLoggedIn = Boolean(authToken);

  return (
    <AuthContext.Provider
      value={{
        firebaseAuth,
        authToken,
        authTokenParsed,
        isLoggedIn,
        isImpersonating,
        err: isError ? err : undefined,
        isLoading:
          loginResult.isLoading ||
          firebaseSignInWithEmailAndPasswordLoading ||
          firebaseAuthStateLoading ||
          firebasePasswordResetEmailIsLoading,
        localLogin,
        logout,
        clearErrors,
        firebaseSignInWithEmailAndPassword,
        firebaseSendPasswordResetEmail,
        firebaseCreateUserWithEmailAndPassword,
        startImpersonate,
        endImpersonate,
        refreshToken,
        firebaseMFA,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useRefreshAuthToken({
  authToken,
  authTokenParsed,
  logout,
  setAuthToken,
}: {
  authToken: string | undefined;
  authTokenParsed: AuthTokenPayload | null;
  logout: () => void;
  setAuthToken: (t: string) => void;
}) {
  const refreshTokenQuery = useRefreshTokenQuery(
    {},
    {
      skip: !authToken,
      pollingInterval: authToken ? 1000 * 60 * 15 : undefined,
    }
  );

  useEffect(() => {
    const tokenOrError = getTokenPayloadOrAuthError(refreshTokenQuery.data);
    if (
      !!refreshTokenQuery.error ||
      (tokenOrError && tokenOrError.__typename === "AuthError")
    ) {
      // if the api fails but the token hasn't expired we can stay logged in
      if (authTokenParsed && authTokenParsed?.exp > Date.now() / 1000) return;
      logout();
      return;
    }

    if (!tokenOrError) return;
    if (tokenOrError.__typename === "TokenPayload") {
      setAuthToken(tokenOrError.token);
    }
  }, [refreshTokenQuery.data, refreshTokenQuery.error]);

  return refreshTokenQuery;
}

export function useAuthCookie() {
  const [cookies, setCookie, removeCookie] = useCookies(["auth", "_su"]);
  const authToken = cookies.auth;
  const superUserAuthToken = cookies._su;
  const [authTokenParsed, setParsedToken] = useState(() =>
    authToken ? parseJwtPayload(authToken) : null
  );

  const superUserAuthTokenParsed = useMemo(() => {
    return superUserAuthToken ? parseJwtPayload(superUserAuthToken) : null;
  }, [superUserAuthToken]);

  const isImpersonating = Boolean(
    superUserAuthToken &&
      authTokenParsed?.userId !== superUserAuthTokenParsed?.userId
  );

  const removeAuthToken = () => {
    removeCookie("auth", {
      path: "/",
    });
    removeCookie("_su", {
      path: "/",
    });
    setParsedToken(null);
  };

  const setAuthToken = (token: string, ignoreSuper?: boolean) => {
    const nextParsedToken = parseJwtPayload(token);
    setParsedToken(nextParsedToken);
    const cookieOpts: CookieAttributes = {
      path: "/",
      expires: nextParsedToken.exp
        ? new Date(nextParsedToken.exp * 1000)
        : addHours(new Date(), 1),
    };

    setCookie("auth", token, cookieOpts);

    if (authTokenParsed?._super && !ignoreSuper) {
      setCookie("_su", token, cookieOpts);
    }
  };

  const startImpersonate = (token: string) => {
    if (!superUserAuthToken) return;
    setAuthToken(token, true);
  };

  const endImpersonate = () => {
    if (!superUserAuthToken) return;
    setAuthToken(superUserAuthToken);
  };

  return {
    authToken,
    authTokenParsed,
    superUserAuthToken,
    isImpersonating,
    setAuthToken,
    removeAuthToken,
    startImpersonate,
    endImpersonate,
  };
}

type TokenPayloadResponses =
  | undefined
  | FirebaseAppRegisterMutation
  | FirebaseAppLoginMutation
  | LocalLoginMutation
  | RefreshTokenQuery;

function getTokenPayloadOrAuthError(
  ...responses: TokenPayloadResponses[]
): AuthError | TokenPayload | null {
  const response = responses.find((p) => Boolean(p));
  if (!response) return null;
  return Object.values(response).find(
    (r) => r?.__typename === "AuthError" || r?.__typename === "TokenPayload"
  );
}
