import { useCallback, useState } from "react";

export function usePromiseLoading<
  T extends (...args: any[]) => Promise<any> | any,
>(p: T, deps: React.DependencyList) {
  const [isLoading, setIsLoading] = useState(false);

  const _pCallback = useCallback(p, deps);

  const wrapped = useCallback(
    async (...args: Parameters<T>) => {
      setIsLoading(true);
      const maybePromise = _pCallback(...args);
      const isPromise = maybePromise instanceof Promise;
      if (!isPromise) {
        setIsLoading(false);
        return maybePromise;
      }

      return maybePromise.finally(() => setIsLoading(false));
    },
    [_pCallback]
  );

  return [wrapped as T, isLoading] as const;
}
