import { TerrainByDimension } from "@duet/shared/models/BlockLayerModel";
import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { compact } from "lodash";
import { GetRasterLayersQuery, api } from "~/gql/generated";
import { RootState, useAppSelector } from "~/redux/store";

type RasterLayer = Omit<GetRasterLayersQuery["rasterLayers"][0], "__typename">;

export interface ProjectDesignState {
  selectedDesignBlockLayerIds: string[];
  selectedDesignBlockIds: string[];
  drawModeOptions: Record<string, any> | undefined;
  drawingMode:
    | "single_select"
    | "simple_select"
    | "direct_select"
    | "draw_polygon"
    | "select_transform"
    | "draw_rectangle"
    | "draw_pv_array_fill"
    | "draw_alignment_line";
  drawLayerType: "perimeter" | "section" | "guide_line" | null | undefined;
  rasterLayers: RasterLayer[];
  rasterLayerEditId: string | null;
  redrawLayerId: string | null;
  terrainEnabled: boolean;
  terrainRendered: boolean;
  terrainIsobands: boolean;
  terrainExaggeration: number;
  terrainByDimension: TerrainByDimension;
  isTerrainInspectMode: boolean;
}

const getInitialState = (): ProjectDesignState => {
  const rawTerrainExaggeration = localStorage.getItem("terrain-exaggeration");
  let terrainExaggeration = 1;
  if (rawTerrainExaggeration) {
    terrainExaggeration = Number(rawTerrainExaggeration);
  }
  const terrainEnabled = localStorage.getItem("terrain-enabled") === "true";

  return {
    selectedDesignBlockIds: [],
    selectedDesignBlockLayerIds: [],
    drawingMode: terrainEnabled ? "single_select" : "simple_select",
    drawLayerType: null,
    drawModeOptions: undefined,
    rasterLayers: [],
    rasterLayerEditId: null,
    redrawLayerId: null,
    terrainEnabled,
    terrainRendered: false,
    terrainIsobands: false,
    terrainExaggeration,
    terrainByDimension: "table",
    isTerrainInspectMode: false,
  };
};

export const projectDesignSlice = createSlice({
  name: "projectDesign",
  initialState: getInitialState,
  reducers: {
    setDrawingMode(
      state,
      action: PayloadAction<
        Pick<ProjectDesignState, "drawingMode" | "drawLayerType"> & {
          blockId?: string;
          drawModeOptions?: Record<string, any>;
          redrawLayerId?: string;
        }
      >
    ) {
      const { drawingMode, drawLayerType, redrawLayerId, blockId } =
        action.payload;
      state.drawingMode = drawingMode;

      if (drawingMode === "simple_select" && state.redrawLayerId) {
        state.redrawLayerId = null;
      }

      if (typeof drawLayerType !== "undefined") {
        state.drawLayerType = drawLayerType;
      }

      if (blockId) {
        state.selectedDesignBlockIds = [blockId];
      }

      if (redrawLayerId) {
        state.redrawLayerId = redrawLayerId;
      }

      state.drawModeOptions = action.payload.drawModeOptions;
    },
    addBlockLayerSelection(state, action: PayloadAction<string[] | string>) {
      if (state.isTerrainInspectMode) {
        state.selectedDesignBlockLayerIds = [];
        return;
      }
      if (Array.isArray(action.payload)) {
        state.selectedDesignBlockLayerIds = action.payload;
        return;
      }
      state.selectedDesignBlockLayerIds = [action.payload];
    },
    removeBlockLayerSelection(state, action: PayloadAction<string[] | string>) {
      const payload = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      state.selectedDesignBlockLayerIds =
        state.selectedDesignBlockLayerIds.filter((id) => !payload.includes(id));
    },
    clearBlockLayerSelection(state) {
      state.selectedDesignBlockLayerIds = [];
    },
    addDesignBlockSelection(state, action: PayloadAction<string[] | string>) {
      if (Array.isArray(action.payload)) {
        state.selectedDesignBlockIds = action.payload;
        return;
      }
      state.selectedDesignBlockIds = [action.payload];
    },
    removeDesignBlockSelection(
      state,
      action: PayloadAction<string[] | string>
    ) {
      const payload = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      state.selectedDesignBlockIds = state.selectedDesignBlockIds.filter(
        (id) => !payload.includes(id)
      );
    },
    clearDesignBlockSelection(state) {
      state.selectedDesignBlockIds = [];
    },
    updateRasterLayer(
      state,
      action: PayloadAction<Partial<RasterLayer> & { id: string }>
    ) {
      const rasterLayer = state.rasterLayers.find(
        (layer) => layer.id === action.payload.id
      );

      if (!rasterLayer) {
        return;
      }

      Object.assign(rasterLayer, action.payload);
    },
    setRasterLayerEditId(state, action: PayloadAction<string | null>) {
      state.rasterLayerEditId = action.payload;
    },
    setRedrawSectionId(state, action: PayloadAction<string | null>) {
      state.redrawLayerId = action.payload;
    },
    setIsTerrainInspectMode(state, action: PayloadAction<boolean>) {
      state.isTerrainInspectMode = action.payload;

      if (action.payload && !state.terrainEnabled) {
        state.terrainEnabled = true;
      }
    },
    setTerrainEnabled(state, action: PayloadAction<boolean>) {
      state.terrainEnabled = action.payload;
      if (action.payload) {
        state.drawingMode = "single_select";
        return;
      }

      if (state.drawingMode === "single_select") {
        state.drawingMode = "simple_select";
      }
    },
    setTerrainRendered(state, action: PayloadAction<boolean>) {
      state.terrainRendered = action.payload;
    },
    setTerrainIsobands(state, action: PayloadAction<boolean>) {
      state.terrainIsobands = action.payload;
    },
    setTerrainExaggeration(state, action: PayloadAction<number>) {
      state.terrainExaggeration = action.payload;
    },
    setTerrainByDimension(state, action: PayloadAction<TerrainByDimension>) {
      state.terrainByDimension = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        projectDesignSlice.actions.setTerrainEnabled.match,
        projectDesignSlice.actions.setTerrainExaggeration.match
      ),
      (state) => {
        localStorage.setItem("terrain-enabled", String(state.terrainEnabled));
        localStorage.setItem(
          "terrain-exaggeration",
          String(state.terrainExaggeration)
        );
      }
    );

    builder.addMatcher(
      api.endpoints.CreateRasterLayer.matchFulfilled,
      (state, { payload }) => {
        state.rasterLayers.push(payload.createRasterLayer);
        state.rasterLayerEditId = payload.createRasterLayer.id;
      }
    );

    builder.addMatcher(
      api.endpoints.GetRasterLayers.matchFulfilled,
      (state, { payload }) => {
        const localState = state.rasterLayers;
        const serverState = payload.rasterLayers;

        const nextLayers = serverState.map((serverLayer) => {
          const localLayer = localState.find((l) => l.id === serverLayer.id);
          return {
            ...localLayer,
            ...serverLayer,
          };
        });

        state.rasterLayers = nextLayers;
      }
    );

    builder.addMatcher(
      api.endpoints.UpdateRasterLayer.matchFulfilled,
      (state, { payload }) => {
        const localState = state.rasterLayers;
        const updatedLayer = payload.updateRasterLayer;

        const rasterLayer = localState.find(
          (layer) => layer.id === updatedLayer.id
        );
        if (!rasterLayer) {
          return;
        }

        Object.assign(rasterLayer, updatedLayer);
      }
    );

    builder.addMatcher(
      api.endpoints.DeleteRasterLayer.matchFulfilled,
      (state, { payload }) => {
        state.rasterLayers = state.rasterLayers.filter(
          (l) => l.id !== payload.deleteRasterLayer
        );
      }
    );
  },
});

export const {
  setDrawingMode,
  addDesignBlockSelection,
  removeDesignBlockSelection,
  clearDesignBlockSelection,
  addBlockLayerSelection,
  clearBlockLayerSelection,
  removeBlockLayerSelection,
  updateRasterLayer,
  setRasterLayerEditId,
  setRedrawSectionId,
  setTerrainRendered,
  setTerrainExaggeration,
  setTerrainEnabled,
  setTerrainIsobands,
  setTerrainByDimension,
  setIsTerrainInspectMode,
} = projectDesignSlice.actions;

export const useProjectDesignState = () => {
  return useAppSelector((state) => {
    return {
      ...state.projectDesign,
      selectedDesignBlocks: compact(
        state.projectDesign.selectedDesignBlockIds.map(
          (id) => state.projectBlock.blocksById[id]
        )
      ),
      selectedBlockLayers: selectedBlockLayersSelector(state),
    };
  });
};

export function selectedBlockLayersSelector(state: RootState) {
  return compact(
    state.projectDesign.selectedDesignBlockLayerIds.map(
      (id) => state.projectBlock.blockLayersById[id]
    )
  );
}
