import { useEffect, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getRefererAndInviteId, useAppUrls } from "~/appUrls";
import { LoadingOverlay } from "~/components/LoadingOverlay/LoadingOverlay";
import { useFeatureFlags } from "../featureFlag/hooks";
import { useActiveUser } from "../user/hooks";
import { useAuth } from "./AuthContext";

export function AuthRoute({ component }: { component: React.ReactNode }) {
  const { isLoggedIn, authTokenParsed, logout } = useAuth();
  const { isGaOpenRegistrationEnabled } = useFeatureFlags([
    "ga-open-registration",
  ]);
  const urls = useAppUrls();
  const location = useLocation();

  const { user, isLoading, error } = useActiveUser();

  useEffect(() => {
    if (!error) return;
    if (error.message === "Unauthorized") {
      logout();
    }
  }, [error]);

  const wasLoggedIn = useRef(false);
  const pageContent = <>{component}</>;

  if (isLoggedIn) {
    wasLoggedIn.current = true;
  }

  if (!isLoggedIn) {
    if (wasLoggedIn.current) {
      // if we were previously logged in and now we're not, we should redirect to the login page and ignore the current location
      // if the referer was set intentionally we will include it
      const referer = new URLSearchParams(location.search).get("referer");
      return <Navigate to={urls.loginRoute(referer || undefined)} />;
    }
    // if we're visiting a link directily and not logged in we should redirect to the login page
    // we are passing the current location as the referer
    const { referer, invite_id } = getRefererAndInviteId(location);

    return <Navigate to={urls.loginRoute(referer, invite_id)} />;
  }

  if (isLoading) {
    return <LoadingOverlay isLoading />;
  }

  if (!isGaOpenRegistrationEnabled) {
    return pageContent;
  }

  // all the following checks are for the open registration onboarding flow
  const shouldDoEmailVerification =
    authTokenParsed?.intercept === "email_verification";

  if (shouldDoEmailVerification) {
    if (location.pathname === urls.emailVerification().pathname) {
      return pageContent;
    }

    return <Navigate to={urls.emailVerification()} />;
  }

  const shouldDoOnboardingIntake = !user?.has_lead_intake_form;

  if (shouldDoOnboardingIntake) {
    if (location.pathname === urls.onboardingIntakeForm().pathname) {
      return pageContent;
    }
    return <Navigate to={urls.onboardingIntakeForm().toString()} />;
  }

  return pageContent;
}
