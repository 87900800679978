import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { useAuth } from "./AuthContext";

export function useDevLogin(
  form: UseFormReturn<{ username: string; password: string }>
) {
  const auth = useAuth();

  function handleLogin(su?: boolean) {
    let user = import.meta.env.VITE_USERNAME;
    if (su) user = user.replace("dev", "dev+su");
    form.setValue("username", user);
    form.setValue("password", import.meta.env.VITE_PASSWORD);
  }

  useEffect(() => {
    // dev login shortcut
    if (import.meta.env.DEV) {
      const keydownListener = (evt: KeyboardEvent) => {
        const isDevLoginShortcut =
          evt.key === "Enter" && (evt.ctrlKey || evt.metaKey);

        if (isDevLoginShortcut) {
          const isSuper = evt.shiftKey;

          handleLogin(isSuper);
        }
      };
      window.addEventListener("keydown", keydownListener);
      return () => window.removeEventListener("keydown", keydownListener);
    }

    return () => {};
  }, [auth]);
}
