import {
  calculatePvPanelDimensions,
  calculatePvTableDimensions,
} from "@duet/shared/pvComponents/legacyCalculateDimensions";
import { startCase, uniqueId } from "lodash";
import { v4 as uuid } from "uuid";

// These types and interfaces are copied from the app's generated BlockFragment
// type so that we have a platform-agnostic way to reference blocks and their
// associated components. In the longer term we may wan to just do the same
// graphql code generation we do for the app (or move it and have the app
// from here).

export enum TerrainType {
  Flat = "flat",
  Slope = "slope",
}

export type PvPanel = {
  panel_edge_lr?: number | null;
  panel_edge_b?: number | null;
  panel_edge_t?: number | null;
  cell_length?: number | null;
  cell_width?: number | null;
  cell_spacing_lr?: number | null;
  cell_spacing_tb?: number | null;
  half_cut_center_space?: number | null;
  cells?: Array<number | null> | null;
  ideality_factor?: number | null;
  short_circuit_current?: number | null;
  open_circuit_voltage?: number | null;
  series_resistance?: number | null;
  shunt_resistance?: number | null;
  cell_type?: string | null;
};

export type PvTable = {
  num_cols?: any | null;
  num_tiers?: number | null;
  row_offset?: any | null;
  row_spacing?: number | null;
  table_gap?: any | null;
  tier_gap?: any | null;
  column_gap?: number | null;
  azimuth?: number | null;
  orientation_str?: string | null;
};

export interface Block {
  id: string;
  created_at: any;
  updated_at: any;
  name: string;
  enviro_dataset_id?: string | null;
  pv_system_start_date?: string | null;
  cleaning_interval_days?: number | null;
  soiling_rate?: number | null;
  power_factor?: number | null;
  date_of_most_recent_cleaning?: string | null;
  pv_soiling_algorithm?: string | null;
  soiling_rates_percentage?: any | null;
  num_inverters?: number | null;
  slope_azimuth?: number | null;
  slope_tilt?: number | null;
  follow_slope?: boolean | null;
  slope_aware_tracking?: boolean | null;
  terrain_type: TerrainType;
  pv_table_id?: string | null;
  pv_panel_id?: string | null;
  pv_inverter_id?: string | null;
  pv_transformer_id?: string | null;
  pv_dc_wire_id?: string | null;
  pv_ac_wire_id?: string | null;
  pv_panel?: PvPanel | null;
  pv_table?: PvTable | null;
}

const EMPTY_BLOCK_MODEL: Block = {
  id: "",
  name: "",
  pv_panel_id: null,
  pv_table_id: null,
  pv_inverter_id: null,
  pv_transformer_id: null,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  // sim params
  enviro_dataset_id: undefined,
  date_of_most_recent_cleaning: undefined,
  soiling_rate: 0.0,
  cleaning_interval_days: undefined,
  pv_system_start_date: undefined,
  power_factor: 1.0,
  pv_soiling_algorithm: "monthly",
  soiling_rates_percentage: [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  ],
  slope_azimuth: 0.0,
  slope_tilt: 0.0,
  slope_aware_tracking: true,
  terrain_type: TerrainType.Flat,
};

export function makeBlockModel(block?: Partial<Block>): Block {
  const id = uuid();
  const name = uniqueId("block_");

  return {
    ...EMPTY_BLOCK_MODEL,
    id,
    name: startCase(name),
    ...block,
  };
}

type NonNullableFields<T> = Required<{
  [P in keyof T]: NonNullable<T[P]>;
}>;

function assertRequiredKeys<T extends Record<string, unknown>>(
  obj: T,
  keys: Array<keyof T>
): [NonNullableFields<T>, Array<keyof T> | undefined] {
  const undefKeys: Array<keyof T> = [];
  for (const key of keys) {
    if (obj[key] === undefined || obj[key] === null) {
      undefKeys.push(key);
    }
  }

  if (undefKeys.length > 0) {
    return [obj as NonNullableFields<T>, undefKeys];
  }
  return [obj as NonNullableFields<T>, undefined];
}

export function calculateBlockSectionTableDimensions(block: Block | undefined) {
  const { pv_panel, pv_table } = block ?? {};
  if (!pv_panel || !pv_table) return null;

  const [pvPanel, nullishPanelKeys] = assertRequiredKeys(pv_panel, [
    "cell_length",
    "cell_width",
    "cell_spacing_lr",
    "cell_spacing_tb",
    "cells",
    "panel_edge_lr",
    "panel_edge_t",
    "panel_edge_b",
  ]);

  if (nullishPanelKeys) return null;

  const [pvTable, nullishTableKeys] = assertRequiredKeys(pv_table, [
    "num_cols",
    "num_tiers",
    "row_spacing",
    "table_gap",
    "column_gap",
    "orientation_str",
  ]);

  if (nullishTableKeys) return null;

  const { panelBoxHeight, panelBoxWidth } = calculatePvPanelDimensions({
    cellHeight: pvPanel.cell_length,
    cellWidth: pvPanel.cell_width,
    cellSpacingX: pvPanel.cell_spacing_lr,
    cellSpacingY: pvPanel.cell_spacing_tb,
    rows: pvPanel.cells?.[0] as number,
    columns: pvPanel.cells?.[1] as number,
    panelPaddingX: pvPanel.panel_edge_lr,
    panelPaddingTop: pvPanel.panel_edge_t,
    panelPaddingBottom: pvPanel.panel_edge_b,
  });
  return calculatePvTableDimensions({
    panelBoxHeight,
    panelBoxWidth,
    columns: pvTable.num_cols,
    // @ts-expect-error
    orientation: pvTable.orientation_str,
    rowSpacing: pvTable.row_spacing,
    tableGap: pvTable.table_gap,
    tierGap: pvTable.tier_gap && pvTable.num_tiers > 1 ? pvTable.tier_gap : 0,
    columnGap: pvTable.column_gap,
    tiers: pvTable.num_tiers,
    azimuth: pvTable.azimuth,
  });
}
