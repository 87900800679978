import { type AppJwtTokenPayload } from "@duet/api/auth/auth.service";
import {
  MultiFactorInfo,
  PhoneMultiFactorInfo,
  TotpMultiFactorInfo,
} from "firebase/auth";
import Cookies from "js-cookie";
export function getAuthToken() {
  return Cookies.get("auth");
}

export function getSuperAuthToken() {
  return Cookies.get("_su");
}

export interface AuthTokenPayload extends AppJwtTokenPayload {
  userId: string;
  _super?: boolean;
  exp: number;
}

export function parseJwtPayload(token: string): AuthTokenPayload {
  return JSON.parse(atob(token.split(".")[1]));
}

export function isPhoneFactor(
  factor: MultiFactorInfo
): factor is PhoneMultiFactorInfo {
  return factor.factorId === "phone";
}

export function isTOTPFactor(
  factor: MultiFactorInfo
): factor is TotpMultiFactorInfo {
  return factor.factorId === "totp";
}
