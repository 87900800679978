import { SerializedError } from "@reduxjs/toolkit";
import { ErrorResponse } from "@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes";
import { FirebaseError } from "firebase/app";
import { type Auth } from "firebase/auth";
import { createContext, useContext } from "react";
import { AuthError } from "~/gql/generated";
import { useFirebaseMFA } from "./useFirebaseAuth";
import { type AuthTokenPayload } from "./util";

export interface AuthContextValue {
  isLoggedIn: boolean;
  authToken: undefined | null | string;
  authTokenParsed: null | AuthTokenPayload;
  err: AuthError | ErrorResponse | SerializedError | FirebaseError | undefined;
  isLoading: boolean;
  firebaseAuth: Auth;
  isImpersonating: boolean;
  localLogin: (username: string, password: string) => Promise<void>;
  logout: () => void;
  clearErrors: () => void;
  firebaseSignInWithEmailAndPassword: (
    email: string,
    password: string,
    invite_id?: string
  ) => Promise<void>;
  firebaseCreateUserWithEmailAndPassword: (
    email: string,
    password: string,
    invite_id?: string
  ) => Promise<void>;
  firebaseSendPasswordResetEmail: (email: string) => Promise<boolean>;
  startImpersonate: (token: string) => void;
  endImpersonate: () => void;
  refreshToken: () => Promise<void>;
  firebaseMFA: ReturnType<typeof useFirebaseMFA>;
}

export const AuthContext = createContext<undefined | AuthContextValue>(
  undefined
);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within AuthProvider");
  }
  return context;
};

export const useIsSuperUser = () => {
  const { authTokenParsed } = useAuth();
  return Boolean(authTokenParsed?._super);
};
