import { ReactNode, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFeatureFlagsQuery } from "~/gql/generated";
import { useActiveUser } from "../user/hooks";

interface FeatureFlagProviderProps {
  children: ReactNode;
}

// this isn't really a provider since we use redux for shared state here,
// but i'm sticking with that react naming convention to signify that some hoisted state/logic is in here
export function FeatureFlagProvider({ children }: FeatureFlagProviderProps) {
  const params = useParams<{ projectId: string }>();
  const { user } = useActiveUser();

  const projectId = params?.projectId;

  // this call produces a side effect that's captured in the featureFlagSlice reducer
  const query = useFeatureFlagsQuery(
    {
      project_id: projectId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (!user?.id) return;
    query.refetch();
  }, [user?.id]);

  return <>{children}</>;
}
