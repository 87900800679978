import { useToast } from "@chakra-ui/react";
import { PhoneMultiFactorInfo, TotpMultiFactorInfo } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "~/features/auth/AuthContext";
import { devFetchAndCopyVerificationCode } from "../useFirebaseAuth";
import { isPhoneFactor, isTOTPFactor } from "../util";
import { MFAList } from "./MFAList";
import { VerifyOTPForm } from "./VerifyOTPForm";
import { VerifySMSCodeForm } from "./VerifySMSForm";

export function MFALoginForm() {
  const { firebaseMFA } = useAuth();
  const hints = firebaseMFA.mfaLoginResolver?.hints ?? [];

  const [searchParams, setSearchParams] = useSearchParams();
  const mfaUid = searchParams.get("mfaUid");

  const selectedHint =
    hints.length === 1
      ? hints[0]
      : firebaseMFA.mfaLoginResolver?.hints?.find(
          (hint) => hint.uid === mfaUid
        );

  if (!selectedHint) {
    return (
      <MFAList
        enrolledMFA={firebaseMFA.mfaLoginResolver?.hints ?? []}
        onSelect={(hint) =>
          setSearchParams((prev) => {
            const next = new URLSearchParams(prev);
            next.set("mfaUid", hint.uid);
            return next;
          })
        }
      />
    );
  }

  if (isPhoneFactor(selectedHint)) {
    return <VerifyPhoneMFALogin mfaHint={selectedHint} />;
  }

  if (isTOTPFactor(selectedHint)) {
    return <VerifyTOTPMFALogin mfaHint={selectedHint} />;
  }

  return null;
}

interface VerifySMSMFALoginProps {
  mfaHint: PhoneMultiFactorInfo;
}

function VerifyPhoneMFALogin({ mfaHint }: VerifySMSMFALoginProps) {
  const { firebaseMFA } = useAuth();
  const ref = useRef<HTMLDivElement>(null);
  const toast = useToast({});
  const [verificationId, setVerificationId] = useState<string | null>(null);

  async function sendSMSVerification() {
    if (
      mfaHint.factorId !== "phone" ||
      !ref.current ||
      !firebaseMFA.mfaLoginResolver
    ) {
      return;
    }
    const verifier = firebaseMFA.initRecaptchaVerifier(ref.current);

    const verifyId = await firebaseMFA.verifyPhoneNumber(
      {
        multiFactorHint: mfaHint,
        session: firebaseMFA.mfaLoginResolver.session,
      },
      verifier
    );

    if (!verifyId) return;

    setVerificationId(verifyId);

    //
    if (import.meta.env.DEV && !import.meta.env.VITE_API_PROXY) {
      const code = await devFetchAndCopyVerificationCode();
      toast({
        id: "dev-verification-code",
        title: "DEV Verification Code",
        description: `${code} copied to clipboard`,
        status: "info",
        duration: 10000,
      });
    }
  }

  async function verifySMSCode(code: string) {
    if (!verificationId) return;
    const multiFactorAssertion = await firebaseMFA.verifySMSCode(
      verificationId,
      code
    );
    if (!multiFactorAssertion) return;
    await firebaseMFA.verifyMFALogin(multiFactorAssertion);
  }

  useEffect(() => {
    sendSMSVerification();
  }, [mfaHint]);

  return (
    <>
      <VerifySMSCodeForm
        err={firebaseMFA.err}
        phoneNumber={mfaHint.phoneNumber}
        onSubmit={verifySMSCode}
        onResendSMS={sendSMSVerification}
      />

      <div ref={ref}></div>
    </>
  );
}

interface VerifyTOTPMFALoginProps {
  mfaHint: TotpMultiFactorInfo;
}

function VerifyTOTPMFALogin({ mfaHint }: VerifyTOTPMFALoginProps) {
  const { firebaseMFA } = useAuth();

  async function verifyOTPCode(code: string) {
    const multiFactorAssertion = await firebaseMFA.verifyTOTPCodeForLogin(
      code,
      mfaHint
    );
    if (!multiFactorAssertion) return;
    await firebaseMFA.verifyMFALogin(multiFactorAssertion);
  }

  return <VerifyOTPForm err={firebaseMFA.err} onSubmit={verifyOTPCode} />;
}
