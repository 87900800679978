import {
  Button,
  HStack,
  PinInput,
  PinInputField,
  Stack,
  Text,
} from "@chakra-ui/react";
import { usePromiseLoading } from "@duet/ui/hooks/usePromiseLoading";
import { FirebaseError } from "firebase/app";
import { useEffect, useState } from "react";
import { AuthError } from "../components/AuthError";

interface VerifySMSCodeFormProps {
  phoneNumber: string;
  err?: FirebaseError | null;
  onSubmit: (code: string) => Promise<void>;
  onResendSMS: () => Promise<void>;
}

export function VerifySMSCodeForm({
  phoneNumber,
  err,
  onSubmit,
  onResendSMS,
}: VerifySMSCodeFormProps) {
  const [codeVal, setCodeVal] = useState("");
  const [handleOnSubmit, isLoading] = usePromiseLoading(async () => {
    onSubmit(codeVal);
  }, [codeVal]);

  useEffect(() => {
    if (codeVal.length === 6) {
      handleOnSubmit();
    }
  }, [codeVal]);

  return (
    <Stack
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        handleOnSubmit();
      }}
      spacing={6}
      w="100%"
    >
      <Text>
        We&apos;ve sent a verification code to <b>{phoneNumber}</b>. Please
        enter the code below.
      </Text>

      <HStack align="center" justify="center" py={6}>
        <PinInput
          size="lg"
          otp
          autoFocus
          onComplete={setCodeVal}
          isDisabled={isLoading}
        >
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>

      <ResendSMSWithTimeout sendSMSVerification={onResendSMS} />
      <Button type="submit" variant={"primary"} isLoading={isLoading}>
        Submit
      </Button>
      {err && <AuthError err={err} />}
    </Stack>
  );
}

interface ResendSMSWithTimeoutProps {
  sendSMSVerification: () => Promise<void>;
}

function ResendSMSWithTimeout({
  sendSMSVerification,
}: ResendSMSWithTimeoutProps) {
  const [seconds, setSeconds] = useState(30);
  const [isCoolDown, setIsCoolDown] = useState(false);

  useEffect(() => {
    if (!isCoolDown) return;
    const timer = setInterval(
      () =>
        setSeconds((t) => {
          if (t === 0) {
            setIsCoolDown(false);
            return 30;
          }
          return t - 1;
        }),
      1000
    );
    return () => clearInterval(timer);
  }, [isCoolDown]);

  async function handleResendSMS() {
    await sendSMSVerification();
    setIsCoolDown(true);
  }

  if (isCoolDown) {
    return (
      <Text>
        A new code has been sent via SMS. Please check your phone or try again
        in {seconds}s.
      </Text>
    );
  }

  return (
    <Text>
      Didn&apos;t receive the code?{" "}
      <Button variant="link" onClick={handleResendSMS} isDisabled={isCoolDown}>
        Click here to resend the SMS.
      </Button>
    </Text>
  );
}
