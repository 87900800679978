import {
  Button,
  HStack,
  PinInput,
  PinInputField,
  Stack,
  Text,
} from "@chakra-ui/react";
import { usePromiseLoading } from "@duet/ui/hooks/usePromiseLoading";
import { FirebaseError } from "firebase/app";
import { ReactNode, useEffect, useState } from "react";
import { AuthError } from "./AuthError";

interface VerifyOTPFormProps {
  text?: ReactNode;
  err?: FirebaseError | null;
  onSubmit: (code: string) => Promise<void>;
}

export function VerifyOTPForm({ text, err, onSubmit }: VerifyOTPFormProps) {
  const [codeVal, setCodeVal] = useState("");
  const [handleOnSubmit, isLoading] = usePromiseLoading(async () => {
    onSubmit(codeVal);
  }, [codeVal]);

  useEffect(() => {
    if (codeVal.length === 6) {
      handleOnSubmit();
    }
  }, [codeVal]);

  return (
    <Stack
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        handleOnSubmit();
      }}
      spacing={6}
      w="100%"
    >
      {text || <Text>Enter the 6-digit code from your authenticator app.</Text>}
      <HStack align="center" justify="center" py={6}>
        <PinInput
          size="lg"
          otp
          autoFocus
          onComplete={setCodeVal}
          isDisabled={isLoading}
        >
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>
      <Button type="submit" variant={"primary"} isLoading={isLoading}>
        Submit
      </Button>
      {err && <AuthError err={err} />}
    </Stack>
  );
}
